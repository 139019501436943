
@import './style/buttons.scss';
@import './style/dropdown.scss';
@import './style/forms.scss';
@import './style/modals.scss';
@import './style/typography.scss';
@import './style/material-overrides.scss';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
@import "~@ng-select/ng-select/themes/material.theme.css";
// Plus imports for other components in your app.

@mixin icomoon() {
  /* use !important to prevent issues with browser extensions that change fonts */
  // font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();


// @include icomoon();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$lafargeOrders-primary: mat-palette($mat-indigo);
$lafargeOrders-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$lafargeOrders-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$lafargeOrders-theme: mat-light-theme((
  color: (
    primary: $lafargeOrders-primary,
    accent: $lafargeOrders-accent,
    warn: $lafargeOrders-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($lafargeOrders-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
