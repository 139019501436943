* {
  font-family: 'Source Sans Pro', sans-serif;
}

h1 {}
h2 {}
h3 {
  color: #333333;
  font-size: 22px;
  font-weight: 200;
}

.note {
  color: #72839B;
  font-size: 14px;
}
