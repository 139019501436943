.btn {
  height: 36px;
  line-height: 32px;
  border-radius: 4px;
  padding: 0 20px 2px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.3s;
  text-decoration: none;
  &:disabled {
    opacity: 0.3;
  }
  &:focus {
    outline: none;
  }
  &.loading {
    color: rgba(0,0,0,0);
    position: relative;
    &:before {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 50%;
      opacity: 1;
      background: url(/assets/img/spinner_22x22.png) center 0 no-repeat transparent;
      animation: loading 0.8s steps(19) infinite;
      content: '';
      display: inline-block;
      height: 22px;
      width: 22px;
    }
  }
  .icon-plus {
    opacity: 0.6;
    transform: scale(1.5) translate(-2px, 1px);
    display: inline-block;
  }

  i {
    margin-right: 10px;
    font-size: 15px;
    margin-top: -2px;
    display: inline-block;
    vertical-align: middle;
  }
}
@keyframes loading {
  100% { background-position: center -418px; }
}

@keyframes loading-small {
  100% { background-position: center -304px; }
}

@mixin buttonStates($color) {
  background-color: $color;
  &:hover, &:focus {
    background-color: lighten($color, 5%);
  }
  &:active {
    background-color: darken($color, 5%);
  }
  &:disabled {
    background: $color;
  }
  &.loading {
    background-color: $color;
    opacity: 1;
  }
}

$green: #00c853;
.btn-primary {
  color: #FFFFFF;
  @include buttonStates($green);
  &:disabled {
    background-color: $green;
  }
  .md-button-wrapper,
  .mat-button-wrapper {
    color: #FFFFFF;
    font-size: 16px;
  }
}
$blue: #2a81d7;
.btn-primary-alt {
  color: #FFFFFF;
  @include buttonStates($blue);
  &:disabled {
    background-color: $blue;
  }
  .md-button-wrapper,
  .mat-button-wrapper {
    color: #FFFFFF;
    font-size: 16px;
  }
}
.btn-cancel {
  @include buttonStates(#666);
  color: #FFFFFF;
  .md-button-wrapper,
  .mat-button-wrapper {
    color: #FFFFFF;
    font-size: 16px;
  }
}

.btn-warning {
  @include buttonStates(#F6A623);
  .md-button-wrapper,
  .mat-button-wrapper {
    color: #FFFFFF;
    font-size: 16px;
  }
}

.btn-failure {
  @include buttonStates(#D0011B);
  .md-button-wrapper,
  .mat-button-wrapper {
    color: #FFFFFF;
    font-size: 16px;
  }
}

.btn-delete {
  @include buttonStates(#ed1c24);
  .md-button-wrapper,
  .mat-button-wrapper {
    color: #FFFFFF;
    font-size: 16px;
  }
}

.btn-inverted-delete, .btn-primary-inverted, .btn-default {
  @include buttonStates(#f8fafc);
  color: #ed1c24;
  &:before {
    mix-blend-mode: exclusion;
  }
  .md-button-wrapper,
  .mat-button-wrapper {
    color: #ed1c24;
    font-size: 16px;
  }
}

.btn-default {
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  color: #354052;
  .md-button-wrapper,
  .mat-button-wrapper {
    color: #354052;
    font-size: 16px;
  }
  &.active {
    color: #2a81d7;
    .md-button-wrapper,
    .mat-button-wrapper {
      color: #2a81d7;
    }
  }
}

.btn-primary-inverted {
  @include buttonStates(#f8fafc);
  color: #354052;
  .md-button-wrapper,
  .mat-button-wrapper {
    color: #354052;
    font-size: 16px;
  }
}

.btn-light {
  @include buttonStates(#f8fafc);
  color: #7f8fa4;
  .md-button-wrapper,
  .mat-button-wrapper {
    color: #7f8fa4;
    font-size: 16px;
  }
}

.btn-small {
  height: 18px;
  line-height: 16px;
  border-radius: 4px;
  padding: 0 10px 2px;
  font-size: 12px;
  margin: 0;
}

.mobile-view-controls {
  width: 100%;
  height: 40px;
  button {
    width: 50%;
    height: 100%;
    font-weight: 600;
    background-color: white;
    border: none;
    border-radius: 0;
    border-right: 1px solid #f8fafc;
    border-bottom: 1px solid #f8fafc;
    color: #7f8fa4;
    &.active {
      border-bottom: 3px solid #2a81d7;
      color: #2a81d7;
    }
    &:last-child { border-right: none; }
    &:focus, &:active {
      outline: none;
    }
  }
}
