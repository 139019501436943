// No hover circle
.md-button-focus-overlay,
.md-button-ripple,
.mat-button-focus-overlay,
.mat-button-ripple {
  display: none;
}

.mat-dialog-container {
  max-width: unset !important;
  // max-height: calc(100VH - 20px) !important;

  .mat-dialog-content {
    // max-height: calc(100VH - 200px);
    // overflow-y: auto;
  }
}

.md-menu-panel,
.mat-menu-panel {
  border-radius: 4px;
	background-color: #ffffff;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #dfe3e9;

  .md-menu-content,
  .mat-menu-content,
  .mat-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    margin-bottom: 0;

    a,
    button {
      font-size: 14px;
      font-weight: 600;
      font-family: 'Source Sans Pro', sans-serif;
      text-align: left;
      color: #354052;
      height: 37px;
      line-height: 37px;
      padding: 0 14px;
      text-decoration: none;
      border: none;
      border-bottom: 1px solid #dfe3e9;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: #f1f4f8;
        color: #2a81d7;
      }
    }
  }
}

.cdk-column-actions {
  white-space: nowrap;
  min-width: 60px;
}

.mat-header-cell {
  button {
    text-transform: uppercase;
  }
}

.mat-form-field-appearance-legacy {
  .mat-form-field-wrapper, .mat-form-field-infix { padding: 0; }
}

mat-select {
  .mat-select-value {
    max-width: 100%;
  }
}

mat-option.mat-option {
  &.mat-selected { font-weight: bold; }
  &.mat-option-deselect {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87);
    .mat-pseudo-checkbox-disabled { display: none; }
    &:hover { background: rgba(0, 0, 0, 0.04); }
  }
}

.mat-standard-chip .mat-chip-remove,
.mat-standard-chip .mat-chip-trailing-icon {
  margin-top: -8px;
}

.mat-chip-list-wrapper .mat-standard-chip,
.mat-chip-list-wrapper input.mat-input-element {
  margin: 0 3px 0 0 !important;
}

mat-form-field mat-chip-list .mat-chip-list-wrapper input.mat-chip-input {
  height: 36px !important;
}

mat-form-field mat-chip-list .mat-chip-list-wrapper {
  padding: 0 3px !important
}

.mat-chip-list-wrapper {
  margin: 0 !important;
  margin-right: 3px
}

.mat-standard-chip {
  min-height: 30px;
  margin-right: 3px !important;
}
