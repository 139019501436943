@import './colors.scss';
$dropdown-width: 243px;

* * {
  box-sizing: border-box;
}
// .dropdown {
//   position: relative;
//   font-size: 14px;
//   cursor: pointer;
//   &.open {
//     z-index: 9999;
//     .dropdown-menu {
//       box-shadow:  0 0 4px 3px rgba(0, 0, 0, 0.06);
//       display: block;
//       .dropdown-options .scrollable {
//         overflow-y: auto;
//       }
//     }
//   }
//   &.disabled {
//     .dropdown-button {
//       background: #eaeef1;
//       border: solid 1px #dfe3e9;
//       color: rgba(#354052, 0.5);
//     }
//     .dropdown-menu {
//       display: none;
//     }
//   }
//   &.empty {
//     .dropdown-button {
//       color: rgba(#354052, 0.5);
//     }
//   }
//   &.action, &.user {
//     margin-right: 0;
//     display: inline-block;
//     width: auto;
//     &.empty {
//       .dropdown-button {
//         color: #7f8fa4;
//       }
//     }
//     &.open {
//       .dropdown-button {
//         color: #354052;
//       }
//     }
//     &:focus {
//       .dropdown-button {
//         color: #2a81d7;
//       }
//     }
//     .dropdown-button {
//       display: inline-block;
//       background: none;
//       border: none;
//       font-weight: 400;
//       font-size: 16px;
//       margin-bottom: 0;
//       padding: 0px 27px 8px 0;
//       width: auto;
//       &:hover {
//         color: #2a81d7;
//       }
//       &:after {
//         float: none;
//         margin-left: 1em;
//         color: inherit;
//       }
//     }
//     .dropdown-menu {
//       width: $dropdown-width;
//       .dropdown-options {
//         width: auto;
//       }
//     }
//     &.user {
//       .dropdown-button {
//         color: #fff;
//         font-size: 12px;
//         height: 46px;
//         margin-top: 10px;
//         &:after {
//           margin-left: 10px;
//           opacity: 0.5;
//           position: relative;
//           top: 1px;
//         }
//       }
//       .dropdown-menu {
//         top: 55px;
//         right: 0;
//         left: auto;
//         width: auto;
//       }
//     }
//   }
//   &.searchable {
//     .dropdown-options {
//       height: 230px;
//     }
//   }
//   &.action {
//     .dropdown-menu {
//       top: 37px;
//     }
//     &.bottom-right {
//       .dropdown-menu {
//         right: 50%;
//         top: -50%;
//       }
//     }
//   }
//   &.right-align {
//     .dropdown-menu { right: 0; }
//   }

//   .dropdown-button {
//     white-space: nowrap;
//     overflow: hidden;
//     min-width: 100%;
//     height: 34px;
//     line-height: 34px;
//     vertical-align: middle;
//     border-radius: 4px;
//     background-image: linear-gradient(to top, #f2f4f7, #ffffff);
//     border: solid 1px #ced0da;
//     font-weight: 600;
//     color: #354052;
//     padding: 0px 28px 8px 14px;
//     margin-bottom: 0px;
//     text-overflow: ellipsis;
//     &:focus {
//       outline: none;
//       border-color: #2a81d7;
//     }
//     // &:after {
//     //   @include icomoon();
//     //   content: "\e904";
//     //   position: absolute;
//     //   top: 9px;
//     //   right: 9px;
//     //   color: #a8aab7;
//     //   font-size: 12px;
//     //   transform: scale(2) translateY(2px);
//     // }
//   }
//   .dropdown-button.custom-icon {
//     font-size: 24px;
//     text-align: right;
//     i {
//       color: #354052;
//     }
//   }
//   .dropdown-button.custom-icon:after {
//     content: '';
//   }

//   .dropdown-menu {
//     position: absolute;
//     height: 0;
//     top: 41px;
//     transition: height 0.3s ease-in, box-shadow 0.3s;
//     box-shadow: none;
//     border-radius: 4px;
//     width: 100%;
//     overflow: hidden;
//     min-width: 100%;
//     z-index: 9999;
//     .dropdown-options {
//       left: 0;
//       bottom: 0;
//       min-width: 100%;
//       z-index: 9999;
//       background: #fff;
//       border-radius: 4px;
//       max-height: calc(100VH);
//       border: solid 1px #dfe3e9;
//       display: flex;
//       flex-direction: column;
//       max-height: 220px;
//       .scrollable {
//         flex: 1 1 auto;
//         overflow-y: auto;
//         overflow-x: hidden;
//         -ms-overflow-style: none;
//         &::-webkit-scrollbar, &::-webkit-scrollbar-track {
//           border-radius: 0 0 4px 0;
//         }
//         &::-webkit-scrollbar-track {
//           box-shadow: inset 0 0 4px rgba(0,0,0,0.1);
//         }
//         &::-webkit-scrollbar-thumb {
//           border: 2px solid #fff;
//           background-color: #aaa;
//           border-radius: 10px;
//         }
//         &::-webkit-scrollbar-thumb:window-inactive {
//           background: #eee;
//           all:unset;
//         }
//       }
//     }
//     .option {
//       white-space: nowrap;
//       min-height: 37px;
//       line-height: 37px;
//       vertical-align: middle;
//       padding: 0px 40px 0px 14px;
//       text-align: left;
//       display: flex;
//       flex-direction: row;

//       &.has-image {
//         padding: 8px 40px 12px 14px;
//       }

//       &.disabled {
//         background:#e0e0e0;
//         opacity: 0.4;
//         cursor: not-allowed;
//       }

//       color: #354052;
//       font-weight: 600;
//       box-shadow: inset 0 -1px 0 0 #dfe3e9;
//       position: relative;
//       &:focus, &:hover {
//         outline: none;
//         color: #2a81d7;
//       }
//       &.selected {
//         background: #f1f4f8;
//         color: #2a81d7;
//         &:after {
//           @include icomoon();
//           content: "\e901";
//           position: absolute;
//           top: 7px;
//           right: 8px;
//           font-size: 22px;
//         }
//       }
//       .subtitle {
//         width: 100%;
//         display: block;
//         font-size: 12px;
//         color: #7f8fa4;
//       }
//       .option.ng-star-inserted {
//         float: left;
//         position: relative;
//       }
//       .option-right {
//         line-height: 18px;
//         &.no-subtitle {
//           line-height: 37px;
//         }
//         flex: 0;
//         flex-direction: column;
//         display: flex;
//       }
//       .image {
//         width: 54px;
//       	height: 36px;
//       	border-radius: 2px;
//         margin-right: 10px;
//         display: block;
//         float: left;
//       }
//       .default-image {
//         float: left;
//         width: 54px;
//       	height: 36px;

//         img {
//           border-radius: 2px;
//           width: 100%;
//           height: 100%;
//           object-fit: cover;
//         }

//         i {
//           line-height: 36px;
//           font-size: 26px;
//         }

//         margin-right: 10px;
//         overflow: hidden;
//         background-size: cover;
//         background-position: center center;
//       }
//     }
//     .group-header {
//       padding: 0px 40px 0px 14px;
//       line-height: 36px;
//       vertical-align: middle;
//       color: #7f8fa4;
//       font-weight: 600;
//       box-shadow: inset 0 -1px 0 0 #dfe3e9;
//     }
//     .group .option {
//       padding-left: 24px;
//     }
//     .group.empty .option {
//       padding-left: 14px;
//     }
//     .search-option {
//       flex: 0 0 auto;
//       padding: 4px;
//       height: 44px;
//     }
//     .loader {
//       margin-top:0.4em;
//     }
//   }
// }
input.search {
  padding-right: 2em;
  & + .icon-search {
    line-height: 37px;
    width: 1px;
    color: #ced0da;
    transform: scale(1.75) translateX(-1.1em);
    display: inline-block;
  }
}
.actions-dropdown {
  .dropdown.empty .dropdown-button {
    color: #354052;
  }
  .dropdown.disabled .dropdown-button {
    color: rgba(53, 64, 82, 0.5);
  }
}
@media (max-width: 500px) {
  .dropdown .dropdown-button {
    width: 67%;
    min-width: 0;
  }
}
