input[type='text']:focus,
textarea:focus {
  outline-width: 0;
}

input,
select {
  width: 100%;
  height: 36px;
  background-color: #ffffff;
  font-size: 14px;
  padding: 0 1em;
  border-radius: 4px;
  border: solid 1px #dfe3e9;
  font-weight: 400;
  line-height: 1.36;
  color: #354052;
}

select:disabled,
input:disabled,
textarea:disabled {
  background: #eaeef1;
  border: 1px solid #dfe3e9;
}
.input-group > input:disabled {
  background: #eaeef1;
  border: 0;
}

[type='checkbox'],
[type='radio'] {
  width: auto;
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  width: 16px;
  height: 16px;
  margin-top: -1px;
  &:after {
    content: attr(data-label);
    color: #7f8fa4;
    vertical-align: top;
    margin-top: 0px;
    display: inline-block;
    margin-left: 2em;
    white-space: nowrap;
  }
  &:checked:after {
    font-weight: 600;
    color: #354052;
  }
}
input[type='checkbox'],
input[type='radio'] {
  &:disabled,
  &:read-only {
    background-color: #eaeef1;
    border: solid 1px #dfe3e9;
    &:after {
      opacity: 0.5;
    }
  }
}

label[data-label] {
  display: inline-block;
  margin-bottom: 0;

  &:after {
    content: attr(data-label);
    color: #7f8fa4;
    font-weight: normal;
    vertical-align: top;
    margin-top: 0px;
    display: inline-block;
    white-space: nowrap;
  }

  &.toggle-input-group {
    position: relative;
    &:before {
      content: attr(data-label);
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      color: #7f8fa4;
    }
    &:after { content: none; }
  }
}

input[type='checkbox']:checked + label[data-label]:after,
input[type='radio']:checked + label[data-label]:after {
  font-weight: 600;
  color: #354052;
}

[type='checkbox'] {
  border-radius: 4px;
  background-image: linear-gradient(to top, #f2f4f8, #feffff);
  border: solid 1px #ced0da;
  &:checked {
    background: url(/assets/img/icn-checkmark.svg) 3px 4px no-repeat $green;
    border: solid 1px rgba(0, 0, 0, 0.15);
  }
  &.toggle {
  }
}
[type='radio'] {
  border-radius: 10px;
  background-image: linear-gradient(to top, #f2f4f8, #feffff);
  border: solid 1px #ced0da;
  position: relative;
  cursor: pointer;
  &:checked {
    width: 16px;
    height: 16px;
    background: #00c853;
    border: solid 1px rgba(0, 0, 0, 0.15);
    &:before {
      width: 6px;
      height: 6px;
      background-color: #ffffff;
      content: '';
      display: inline-block;
      border-radius: 50%;
      padding: 0;
      position: absolute;
      top: 4px;
      left: 4px;
    }
  }
}

.toggle {
  width: 38px;
  height: 20px;
  border-radius: 83px;
  background: #dfe1e5;
  transition: background 0.5s;
  position: relative;
  cursor: pointer;
  &:before {
    width: 16px;
    height: 16px;
    border-radius: 83px;
    background-color: #fff;
    transition: left 0.5s;
    content: '';
    display: inline-block;
    top: 1px;
    left: 1px;
    position: absolute;
  }
  &:after {
    margin-left: 3.6em;
  }
  &:checked {
    background: $green;
    &:before {
      left: 19px;
    }
    &:after {
      font-weight: 600;
    }
  }
}
input[type='checkbox'].toggle {
  &:disabled,
  &:read-only {
    background: #eaeef1;
    cursor: default;
  }
}

label {
  display: block;
  margin-bottom: 5px;
  color: $text-light-gray;
  font-weight: 600;
  &.input-field-toggle {
    cursor: pointer;
    color: #2a81d7;
  }
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);
  border: 1px solid #ced0da;
  padding: 0 14px;
  font-weight: 600;
  color: #354052;
  &:focus {
    outline: none;
    border-color: #2a81d7;
  }
  &:invalid {
    color: rgba(#354052, 0.5);
  }
}

input {
  border: solid 1px #dfe3e9;
  &:focus {
    outline: none;
    border: 1px solid #2a81d7;
  }
  &[type='date'],
  &[type='time'],
  &[timeInputMask] {
    background: url(/assets/img/icons/icn-calendar.svg),
      linear-gradient(to top, #f2f4f7, #ffffff);
    background-position: 12px center, 0 0;
    background-repeat: no-repeat;
    font-weight: 600;
    color: #354052;
    padding-left: 40px;

    &::-webkit-calendar-picker-indicator {
      background: transparent;
      border-bottom: 5px solid #a8aab7;
      border-right: 5px solid #a8aab7;
      transform: rotate(45deg) scale(0.2, 0.28);
      color: rgba(0, 0, 0, 0);
    }
  }
  &[type='date'] {
    &::-webkit-inner-spin-button {
      display: none;
    }
  }
}

my-date-picker {
  .mydp {
    border: none;

    .selectiongroup {
      height: 34px;
      background: linear-gradient(to top, #f2f4f7, #ffffff);
      background-color: transparent;
      border: solid 1px #ced0da;
    }

    .btnclear:focus,
    .btndecrease:focus,
    .btnincrease:focus,
    .btnpicker:focus,
    .headertodaybtn:focus {
      background: transparent;
    }

    .btndecrease,
    .btnincrease {
      background-color: transparent;
      border-right: 0;
      border-left: 0;

      &:hover {
        background-color: transparent;
      }
    }
    .selectiondisabled {
      background: none;
    }
    .selectiongroup {
      width: 100%;
    }
    .selection {
      background: url(/assets/img/icons/icn-calendar.svg);
      background-position: 12px 5px, 0 0;
      background-repeat: no-repeat;
      padding-left: 40px;
      font-weight: 600;
      color: #354052;
      padding-bottom: 2px;
      background-color: transparent;
      // border: solid 1px #ced0da;
      font-family: 'Source Sans Pro', sans-serif;
    }
    .btnpicker {
      background-color: transparent;
      border: 0;
      &:hover {
        background-color: transparent;
      }
    }
    .selbtngroup {
      width: 28px;
    }
    .selbtngroup .mydpicon {
      position: absolute;
      right: 0;
      &:before {
        position: absolute;
        color: #a8aab7;
        font-size: 12px;
        transform: scale(2.2) translateY(2px);
      }
    }

    .header {
      .icon-mydpright {
        transform: rotate(-90deg);
        margin-top: 5px;
        &:before {
          @include icomoon();
          content: '\e904';
        }
      }
      .icon-mydpleft {
        transform: rotate(90deg);
        &:before {
          @include icomoon();
          content: '\e904';
          transform: scale(2.5) translateY(2px);
        }
      }
    }

    .headertodaybtn .mydpicon,
    .btnpicker {
      display: none;
    }
    .btndecrease {
      .icon-mydpleft {
        &:before {
          @include icomoon();
          content: '\e91e';
          top: -12px;
          right: 4px;
          font-size: 14px;
        }
      }
    }
    .btnincrease {
      .icon-mydpright {
        &:before {
          @include icomoon();
          content: '\e91f';
          top: -12px;
          right: 6px;
          font-size: 14px;
        }
      }
    }
  }

  &.date-select {
    width: 164px;
    height: 34px;
    margin-top: 0px;

    .mydp {
      height: 34px;
      .selection {
        background: url(/assets/img/icons/icn-calendar.svg);
        background-position: -4px 5px, 0 0;
        background-repeat: no-repeat;
        padding-left: 24px;
      }
    }
  }

  &.fixed {
    .mydp {
      .selector {
        position: fixed;
      }
    }
  }
}

.checkin {
  .mydp {
    .selector {
      right: 0;
    }
  }
  .pin-left {
    .mydp {
      .selector {
        left: 0;
        right: unset;
      }
    }
  }
}

textarea {
  width: 100%;
  height: 150px;
  border-radius: 4px;
  padding: 6px;
  background-color: #ffffff;
  border: solid 1px #dfe3e9;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.36;
  color: #354052;
}

form.ng-invalid,
form.ng-pristine.ng-invalid {
  border: 0;
  background: none;

  &:focus {
    outline: none;
    border: 1px solid #2a81d7;
  }
}

input.ng-invalid.ng-touched {
  border-radius: 4px;
  border: solid 1px #ed1c24;
  color: $red;
}

input.ng-pristine.ng-invalid {
  border-radius: 4px;
  border: solid 1px #dfe3e9;
  color: #354052;

  &:focus {
    outline: none;
    border: 1px solid #2a81d7;
  }
}

.required:after {
  content: ' *';
  color: red;
}

form {
  .label.required:after,
  label.required:after {
    content: ' *';
  }

  &.ng-invalid,
  &.ng-valid[required] {
    // border-left: 0px;
  }

  .toggle-label {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 20px;
    padding-left: 38px;
  }

  .toggle-label .toggle-input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #ccc;
    -ms-transition: 0.5s;
    transition: 0.5s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    top: 2px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -ms-transition: 0.5s;
    transition: 0.5s;
  }

  .toggle-input:checked + .slider {
    background-color: #00c853;
  }

  .toggle-input:focus + .slider {
    box-shadow: 0 0 1px #00c853;
  }

  .toggle-input:checked + .slider:before {
    left: 16px;
  }

  .toggle-input:checked + .toggle-label:after {
    font-weight: 600;
  }

  .slider.round {
    border-radius: 83px;
    right: 4px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.field-group {
  position: relative;
  margin-bottom: 10px;
  &.state:after {
    pointer-events: none;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\E904';
    position: absolute;
    top: 32px;
    right: 8px;
    color: #a8aab7;
    font-size: 12px;
    transform: scale(2) translateY(2px);
  }
}


.input-icon {
  position: absolute;
  height: 0;
  right: 8px;
  bottom: 28px;
  text-align: right;
  font-family: FontAwesome;
  &.ng-invalid {
    margin-left: -28px;
  }
}

.input-icon.ng-valid:before {
  color: #00c853;
  content: '\f00c';
}

.input-icon.ng-invalid:before {
  color: #ed1c24;
  content: '\f00d';
}

.input-icon.ng-pristine:before {
  content: '';
}

.input-pair {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: solid 1px #ced0da;
  overflow: visible;
  background: #fff;

  & > * {
    flex: 1 1 50%;
    border: none;
    border-right: solid 1px #ced0da;
    border-radius: 0 !important;
    &:first-child {
      border-right: solid 1px #ced0da;
    }
  }
  & > input {
    flex: 1 1 auto;
    background: transparent;
  }
  dropdown:first-child {
    .dropdown {
      width: auto;
      .dropdown-button {
        border: none;
        &:after {
          margin-left: 1em;
        }
      }
    }
  }

  .input-group {
    border: none;
  }
}

.input-group {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: solid 1px #ced0da;
  overflow: visible;
  background: #fff;
  height: 36px;
  &[data-prefix] {
    position: relative;
    &:before {
      content: attr(data-prefix);
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      color: #7f8fa4;
      position: absolute;
      top: 9px;
      left: 9px;
    }
    & > :first-child {
      height: 34px;
      padding-left: 18px;
    }
  }
  &[data-suffix] {
    position: relative;
    &:after {
      content: attr(data-suffix);
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      color: #7f8fa4;
      position: absolute;
      top: 9px;
      right: 9px;
    }
    & > :last-child {
      padding-right: 18px;
    }
  }
  & > * {
    flex: 0 0 auto;
    border: none;
    border-right: solid 1px #ced0da;
    border-radius: 0 !important;
    &:last-child {
      border-right: none;
    }
  }
  & > input {
    flex: 1 1 auto;
    background: transparent;
  }
  dropdown:last-child {
    .dropdown {
      width: auto;
      .dropdown-button {
        border: none;
        &:after {
          margin-left: 1em;
        }
      }
    }
  }
  .add-on:last-child {
    background-image: linear-gradient(to top, #f2f4f7, #ffffff);
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
    color: #354052;
  }
}

.edit-drawer {
  overflow-y: scroll;
  .field-group.state:after {
    right: 38px;
  }
}

.timepicker-wrapper {
  background: linear-gradient(to top, #f2f4f7, #ffffff);
  background-color: transparent;
  border: solid 1px #ced0da;
  border-radius: 4px;
  height: 36px;
}
.ui-timepicker-wrapper {
  // margin-left: 44px;
  font-weight: 600;
  z-index: 99999 !important;
}
.timepicker {
  background: url(/assets/img/icons/icn-time.svg);
  background-position: 6px center, 0 0;
  background-repeat: no-repeat;
  border: none;
  padding-left: 44px;
  font-weight: 600;
  color: #354052;

  background-color: transparent;
  font-family: 'Source Sans Pro', sans-serif;

  &:focus {
    border: none;
  }
}

// Segmented Control
.segmented-control {
  display: flex;
  width: 100%;
  padding: 0;
  height: 36px;
  background-image: linear-gradient(to top, #f2f4f7, #ffffff);

  .item:first-child {
    .label {
      border-radius: 4px 0px 0px 4px;
    }
  }

  .item:last-child {
    .label {
      border-radius: 0px 4px 4px 0px;
    }
  }

  .item {
    display: flex;
    flex: 1;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .input {
    position: absolute;
    visibility: hidden;
  }

  .label {
    display: block;
    width: 100%;
    margin: 0 -1px -1px 0;
    padding: 8px 28px 8px 14px;
    border: solid 1px #ced0da;
    text-align: center;
    cursor: pointer;
  }

  .label:hover {
    background: #fafafa;
  }

  .input:checked + .label {
    background: #eee;
    color: #333;
  }
}

.percentage-mask {
  position: relative;
  &:after {
    position: absolute;
    top: 0;
    right: 10px;
    content: '%';
    font-weight: 600;
    color: #7f8fa4;
  }
}

.labelled-input-group {
  display: flex;
  input {
    height: auto;
    width: auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
  .dropdown .dropdown-button, .type-label {
    height: 100%;
    font-size: 12px;
    font-weight: 600;
    padding: 0 25px 0 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .type-label {
    height: 36px;
    border: solid 1px #dfe3e9;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 720px) {
  .ui-timepicker-wrapper {
    position: fixed;
    top: 44px;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    box-shadow: none;
    border: none;
    ul {
      li {
        padding: 12px 15px;
        border-bottom: solid 1px #e7e9ed;
      }
    }
  }
}
