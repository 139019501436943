$text-gray: #354052;
$text-light-gray: #7f8fa4;

// bg colors
$green: #00c853;
$blue: #2a81d7;
$red: #ed1c24;
$dark_gray: #516173;
$orange: #f7981c;
$light_gray: #dfe1e5;
$cancelled_gray: #c7d2e0;
$med_gray: #c2cad4;
$content-background: #f1f4f6;
$edit_blue: #0078d9;
$void_red: #ed1c24;
$retake_orange: #f7981c;
$finalized_green: #00c853;
$alert_yellow: #e0d245;

@mixin icomoon() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
